import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import useSiteMetadata from '../hooks/use-site-metadata'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLongArrowAltRight,
  faLongArrowAltLeft,
} from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'

const PaginationContainer = styled('nav')`
  margin: 20px 0;
`
const MenuPagination = styled('ul')`
  li {
    margin: 0 5px;

    a {
      text-decoration: none;
      border: 1px solid #0a0a0a;
      background: #0a0a0a;
      color: #fff;
      padding: 5px 9px;

      &:hover {
        background: #a18c78;
        border-color: #a18c78;
      }

      &.current-page {
        background: #a18c78;
        border-color: #a18c78;
        font-weight: bold;
        pointer-events: none;
      }
    }

    &.prev {
      margin-right: 20px;
    }

    &.next {
      margin-left: 20px;
    }

    &.prev,
    &.next {
      a {
        background: #a18c78;
        border-color: #a18c78;

        &:hover {
          background: #0a0a0a;
          border-color: #0a0a0a;
        }
      }
    }
    &.middle {
      margin-top: auto;
    }
  }
  @media (max-width: 640px) {
    li {
      a {
        padding: 5px 7px;
      }

      &.prev,
      &.next {
        a {
          padding: 0;
          border: none;
          background: transparent;
          color: #a18c78;

          &:hover {
            color: #0a0a0a;
          }
        }
      }

      &.middle {
        font-size: 0.5rem;
      }

      &.first,
      &.last {
        display: none;
      }
    }
  }
`

const Pagination = ({ pageContext }) => {
  const {
    previousPagePath,
    nextPagePath,
    numberOfPages,
    humanPageNumber: currentPage,
  } = pageContext

  const pageNumberArray = [...new Array(numberOfPages + 1).keys()].slice(1)
  const paginationArray = pageNumberArray.filter(
    (page) =>
      (page >= currentPage && page < currentPage + 3) ||
      page === numberOfPages ||
      page === numberOfPages - 1 ||
      page === numberOfPages - 2
  )

  const { url: siteUrl } = useSiteMetadata()

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${siteUrl}/page/${currentPage}`} />
      </Helmet>
      <PaginationContainer role="navigation">
        <div className="container">
          <MenuPagination className="menu menu-inline menu-center menu-pagination">
            {currentPage !== 1 ? (
              <React.Fragment>
                <li className="prev first" key="begin">
                  <Link to="/page/1" rel="first">
                    First
                  </Link>
                </li>
                <li className="prev" key="prev">
                  <Link to={previousPagePath} rel="next">
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                  </Link>
                </li>
              </React.Fragment>
            ) : null}
            {paginationArray.map((page, index) => (
              <React.Fragment key={index * page}>
                {index === 3 ? (
                  <li key="middle" className="middle">
                    ...
                  </li>
                ) : null}
                <li key={index}>
                  <Link
                    to={page === 1 ? '/page/1/' : `/page/${page}`}
                    activeClassName="current-page"
                  >
                    {page}
                  </Link>
                </li>
              </React.Fragment>
            ))}
            {currentPage !== numberOfPages ? (
              <React.Fragment>
                <li className="next" key="next">
                  <Link to={nextPagePath} rel="next">
                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
                </li>
                <li className="next last" key="last">
                  <Link to={`/page/${numberOfPages}`} rel="last">
                    Last
                  </Link>
                </li>
              </React.Fragment>
            ) : null}
          </MenuPagination>
        </div>
      </PaginationContainer>
    </>
  )
}

export default Pagination
