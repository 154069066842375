import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Pagination from '../components/Pagination'

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWpPost
    const post = posts.map(({ node }) => node)

    return (
      <Layout>
        <PostList filteredPosts={post} title="RECENT POSTS" />
        <Pagination pageContext={pageContext} pathPrefix="/page/1" />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          id
        }
      }
    }
  }
`
